<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="(val) => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>Global Settings</template>

		<v-form
			id="form"
			@submit.prevent
			v-model="isValidForm"
			ref="from"
			class="pt-4"
		>
			<VFormBase :schema="form_structure" :model="form_data"></VFormBase>
		</v-form>
		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				Save Settings
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import VFormBase from "vuetify-form-base";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker, VFormBase },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null },
		},
		data() {
			return {
				isValidForm: false,
				form_data: {},
				form_data_default: {
					communication_active_within_working_hour: "Yes",
					communication_active_outside_working_hour: "Yes",
					working_hours: {
						start_at: null,
						end_at: null,
					},
				},
			};
		},
		mounted() {
			this.form_data = Object.assign(
				this.form_data_default,
				this.$store?.state?.global_settings?.payload
			);
		},
		computed: {
			start_at() {
				return this.form_data?.working_hours?.start_at;
			},
			end_at() {
				return this.form_data?.working_hours?.end_at;
			},
			form_structure() {
				let structure = {
					communication_active_within_working_hour: {
						type: "select",
						name: "communication_active_within_working_hour",
						label: "Sms/Email Communication Active Within Working Hours",
						...this.$helpers.formFieldsBasicLayout,
						items: [
							{ text: "Yes", value: "Yes" },
							{ text: "No", value: "No" },
						],
						itemText: "text",
						itemValue: "value",
						solo: false,
						prependIcon: "mdi-clock-in",
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required()],
					},
					communication_active_outside_working_hour: {
						type: "select",
						name: "communication_active_outside_working_hour",
						label: "Sms/Email Communication Active Outside Working Hours",
						...this.$helpers.formFieldsBasicLayout,
						items: [
							{ text: "Yes", value: "Yes" },
							{ text: "No", value: "No" },
						],
						itemText: "text",
						itemValue: "value",
						solo: false,
						prependIcon: "mdi-clock-out",
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required()],
					},
					working_hours: {
						label: {
							type: "btn",
							label:
								this.start_at && this.end_at
									? `Working Hours ${this.start_at} - ${this.end_at}`
									: "Working Hours",
							...this.$helpers.formFieldsBasicLayout,
							class: "mb-3",
							block: true,
							text: true,
							outlined: false,
						},
						start_at: {
							type: "select",
							name: "start_at",
							label: "Start Time",
							...this.$helpers.formFieldsBasicLayout,
							items: this.$helpers.timeHoursListAm?.reverse(),
							solo: false,
							prependIcon: "mdi-timer",
							col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
							rules: [required()],
						},
						end_at: {
							type: "select",
							name: "end_at",
							label: "End Time",
							...this.$helpers.formFieldsBasicLayout,
							items: this.$helpers.timeHoursListPm?.reverse(),
							solo: false,
							prependIcon: "mdi-timer",
							col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
							rules: [required()],
						},
					},
				};

				return structure;
			},
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let payload = {
					...this.form_data,
				};

				await this.$store
					.dispatch("updateGlobalSettings", { payload })
					.then((res) => {
						this.showMessageFromResponseDta(res.data);
						if (res.data.code == 200) {
							this.closeDialog();
						}
					});
				return true;
			},
		},
	};
</script>
